import Actions from "modules/actions/accounts-actions";

export default {
  name: "AccountModal",
  props: {
    isEditing: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      actions: new Actions(),
      isLoading: false,
      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null)
            return (val && val?.id !== null) || "This field is required";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateEmail: (val) =>
          this.validateEmail(val) || "Invalid email format.",
      },
      account: {
        id: null,
        firstName: "",
        lastName: "",
        email: "",
        profileAssociation: "",
        department: "",
        jobRole: "",
      },
    };
  },
  computed: {
    disableSaveBtn() {
      return Object.values(this.account).includes("") || this.isLoading;
    },
  },
  methods: {
    async saveAccount() {
      try {
        this.isLoading = true;

        const validateForm = await this.$refs.form.validateAsync();

        if (validateForm) {
          const { firstName, lastName, email, department, jobRole } =
            this.account;

          const result = await this.actions.createAccount({
            email: {
              type: "String!",
              value: email,
            },
            firstName: {
              type: "String!",
              value: firstName,
            },
            lastName: {
              type: "String!",
              value: lastName,
            },
            jobRole: {
              type: "String",
              value: jobRole,
            },
            department: {
              type: "String",
              value: department,
            },
          });

          if (result) {
            this.account.id = result.id;

            this.$emit("onSave");
            this.closeModal();

            this.showNotifyMessage({
              type: "success",
              message: "User account profile has been successfully created.",
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "A problem has occured while saving data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "A problem has occured while saving data.",
        });
      } finally {
        this.isLoading = false;
      }
    },
    trimSpaces(val = null, accountField) {
      this.account[accountField] = val
        ? val.replace(/^\s+|\s+/gm, " ").trim()
        : this.account[accountField].replace(/^\s+|\s+/gm, " ").trim();
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
