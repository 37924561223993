import Accounts from "modules/system-maintenance/AccountsPage/Accounts";

export default {
  name: "AccountsPage",
  components: {
    Accounts,
  },
  data() {
    return {
      tab: "",
    };
  },
};
