import Actions from "modules/actions";

import AccountActions from "store/actions/account";

const accountActions = new AccountActions();
export default class AccountsActions extends Actions {
  async createAccount(variables) {
    return await accountActions.create(variables);
  }
}
