import Account from "store/models/Account";

import AccountModal from "modules/system-maintenance/AccountsPage/modals/AccountModal";

export default {
  name: "Accounts",
  components: {
    AccountModal,
  },
  data() {
    return {
      limitData: [
        "firstName",
        "lastName",
        "email",
        "jobRole",
        "department",
        "createdAt",
        "totalCount",
        "vbsStatus",
      ],
      totalCount: 0,
      isDataLoading: false,
      initialDataLoading: false,
      accountModalVisibility: false,
      filter: "",
      searchFilter: {
        columns: 2,
        model: Account,
        fields: [
          {
            label: "Name",
            name: "payorName",
            type: "text",
          },
          {
            label: "Role",
            name: "role",
            fields: ["name"],
          },
          {
            label: "Status",
            name: "status",
            type: "select",
            isEnum: true,
            singleSelect: true,
            selectOptions: ["Activated", "Deactivated"],
          },
          {
            label: "Creation Date",
            name: "created",
            type: "date",
          },
        ],
      },
      columns: [
        {
          name: "name",
          align: "left",
          text: "User",
          field: "name",
          includeFieldSmall: "email",
        },
        {
          name: "department",
          align: "left",
          text: "Department",
          field: "department",
          sortable: true,
        },
        {
          name: "jobRole",
          align: "left",
          text: "Role",
          field: "jobRole",
          sortable: true,
        },
        {
          name: "profileAssociation",
          align: "left",
          text: "Profile Association",
          field: "profileAssociation",
          sortable: true,
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          sortable: true,
          isStatusColumn: true,
        },
        {
          name: "date",
          align: "left",
          text: "Creation Date",
          field: "creationDate",
          isDateColumn: true,
          sortable: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      accounts: [],
      orderBy: {
        jobRole: "orderByRole",
        department: "orderByDepartment",
        profileAssociation: "orderByProfileAssociation",
        creationDate: "orderByDate",
      },
    };
  },
  computed: {
    filteredAccounts() {
      let data = this.accounts.map((item) => {
        const {
          id,
          firstName,
          lastName,
          jobRole,
          department,
          vbsStatus,
          createdAt,
          email,
        } = item;

        let additionalCommands = [];
        const additionalActions = {
          emailCredentials: ["created", "pending"],
          resetPassword: ["activated", "deactivated", "pending"],
          auditTrail: ["activated", "deactivated", "pending"],
        };

        for (let action of Object.keys(additionalActions)) {
          if (
            vbsStatus &&
            additionalActions[action].includes(vbsStatus.toLowerCase())
          ) {
            const actionTitle = action.replace(/([A-Z])/g, " $1");

            additionalCommands.push({
              label: actionTitle.charAt(0).toUpperCase() + actionTitle.slice(1),
              action: () => {
                return;
              },
            });
          }
        }

        return {
          id: id,
          name: `${lastName}, ${firstName}`,
          department,
          jobRole,
          profileAssociation: "--",
          status:
            vbsStatus?.toUpperCase() === "ACTIVATED"
              ? "Active"
              : vbsStatus?.toUpperCase() !== "DEACTIVATED"
              ? vbsStatus
              : "Deactivated",
          creationDate: this.$options.filters.changeDateFormat(
            createdAt,
            "MM/DD/YYYY hh:mm A"
          ),
          inactive: vbsStatus?.toUpperCase() === "DEACTIVATED" || false,
          email,
          additionalMoreCommands: additionalCommands,
        };
      });
      return data;
    },
  },
  methods: {
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.accounts = result;
    },
    async fetch() {
      try {
        this.isDataLoading = true;
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        throw Error(e);
      } finally {
        this.isDataLoading = false;
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    displayAccountModal() {
      this.accountModalVisibility = true;
    },
    async onSave() {
      await this.fetch();
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
  },
  async mounted() {
    try {
      this.initialDataLoading = true;
      await this.fetch();
    } catch (err) {
      this.showNotifyMessage({
        message: "Problem has occurred while fetching data.",
        type: "danger",
      });
    } finally {
      this.initialDataLoading = false;
    }
  },
};
